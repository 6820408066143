import React, { useContext, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { withStyles } from 'react-jss'
import styles from './checkoutFormStyles'
import cx from 'classnames'
import Cart from '../Cart/Cart'
import OUTLET_SUMMARY from './queries/getOutlet.query'
import { getDeliveryZoneFromURL, penceToPounds } from '@utils/helpers'
import { GET_ADDRESS_DELIVERY_COST_V2 } from './queries/getAddressDeliveryCost.query'
import LoginPrompt from './LoginPrompt/LoginPrompt'
import { Query } from 'react-apollo'
import each from 'lodash/each'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import delay from 'lodash/delay'
import get from 'lodash/get'
import ls from '@utils/localStorage'
import { toast } from 'react-toastify'
import { client } from '@services/client'
import { getMarketplace } from '@config/config'
import debounce from 'lodash/debounce'
import Button from '@components/Button/Button'
import StickySideBar, {
  BackLink,
} from '@components/StickySideBar/StickySideBar'
import { GET_ADD_ON_MENUS } from './queries/getAddOnMenuItems.query'
import adultContent from '@images/adult-content.svg'
import veganImage from '@images/allergies/vegan.png'
import dairyFreeImage from '@images/allergies/dairyfree.png'
import glutenFreeImage from '@images/allergies/glutenfree.png'
import vegetarianImage from '@images/allergies/vegetarian.png'
import { ReactSVG } from 'react-svg'
import addSVG from '@images/large-add.svg'
import minusSVG from '@images/large-delete.svg'
import {
  GET_COLLECTION_TOTALS,
  GET_POSTCODE_DELIVERY_TOTALS,
  GET_TABLE_TOTALS,
  GET_ZONE_DELIVERY_TOTALS,
} from './queries/getBasketTotals.query'
import { OrderContext } from '@root/context/Order.context'
import CheckoutFormContent from './CheckoutFormContent'
import { toUpper } from 'lodash'
import { Elements, injectStripe, StripeProvider } from 'react-stripe-elements'
import Alert from '@components/Alert/Alert'
import { BasketContext } from '@root/context/Basket.context'
import { DiscountContext } from '@context/Discount.context'
import { VoucherContext } from '@context/Voucher.context'

const CheckoutEl = ({
  basketContext,
  gtmContext,
  orderContext,
  match,
  history,
  classes,
  outlet,
  stripe,
}) => {
  const basket = basketContext.items
  const [cardToken, setCardToken] = useState(null)
  const [deliveryNotes, setDeliveryNotes] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const hasAgeResrictedItems =
    basket.length && basket.some(item => item.menuItem.ageRestricted)
  const [placingOrder, setPlacingOrder] = useState(false)
  const [addOnMenuItemsOpen, setAddOnMenuItemsOpen] = useState(true)
  const [totalQuantityOfAddOnItems, setTotalQuantityOfAddOnItems] = useState(0)

  const { attributes } = useContext(OrderContext)
  const { appliedDiscountId } = useContext(DiscountContext)
  const { key: voucherKey } = useContext(VoucherContext)

  const totalRef = useRef(0)

  useEffect(() => {
    const currentBasket = basket
    gtmContext.pushDataToGTM({
      event: 'begin_checkout',
      ecommerce: {
        items: currentBasket.reduce((acc, item) => {
          const menuItem = item.menuItem
          const existingItem = acc.find(
            ecomItem => ecomItem.item_id === menuItem.id
          )
          if (existingItem) {
            existingItem.quantity = existingItem.quantity + 1
          } else {
            const item = {
              item_name: menuItem.name,
              item_id: menuItem.id,
              price: menuItem.price,
              quantity: 1,
            }
            acc = [...acc, item]
          }
          return acc
        }, []),
      },
    })

    const logInOutSuccess = () => {
      const token = ls.get('jwt')
      setIsLoggedIn(!isEmpty(token))
    }

    logInOutSuccess()
    delay(() => {
      window.scrollTo(0, 0)
    }, 100)

    ls.pushListener({
      key: 'jwt',
      update: logInOutSuccess,
    })

    //cleanup
    return () => {
      ls.popListener('jwt')
    }
  }, [])

  useEffect(() => {
    const attributes = orderContext.attributes
    const fulfilmentChosenId = attributes.fulfilmentChosen
      ? attributes.fulfilmentChosen.id
      : null
    if (
      !attributes.deliveryZone &&
      (attributes.address || fulfilmentChosenId)
    ) {
      const checkIfNetwork = async () => {
        const { attributes, updateOrder } = orderContext
        const { address, outletId, fulfilmentChosen } = attributes
        if (
          address &&
          fulfilmentChosen &&
          (fulfilmentChosen.id === 'delivery' ||
            fulfilmentChosen.id === 'network')
        ) {
          const { data } = await client.query({
            query: GET_ADDRESS_DELIVERY_COST_V2,
            variables: { addressId: address, outletId },
            context: { version: 2 },
          })
          if (data) {
            const { type } = data.deliveryEstimateForAddress
            if (type === 'NETWORK_ESTIMATE' || type === 'NETWORK_FALLBACK') {
              updateOrder({
                fulfilmentChosen: { id: 'network', label: 'Delivery' },
              })
            }
          }
        }
      }
      checkIfNetwork()
    }
  }, [
    orderContext.address,
    orderContext.outletId,
    orderContext.fulfilmentChosen,
  ])

  const conditionallySetCardToken = newCardToken => {
    if (newCardToken !== cardToken) setCardToken(newCardToken)
  }

  const getOrderObject = async ({ outlet, needsAddress, isOrderToTable }) => {
    const deliveryZone = await getDeliveryZoneFromURL(match)
    const savedBasket = basketContext.items
    const orderSoFar = orderContext.attributes
    const {
      fulfilmentChosen,
      address,
      orderNotes,
      paymentMethod,
      asap,
      selectedDeliverySlot,
      selectedDeliveryWindow,
      table,
    } = orderSoFar

    const zone = deliveryZone && deliveryZone.id ? deliveryZone.id : ''

    if (isOrderToTable) {
      return {
        orderData: {
          outletId: outlet.id,
          tableId: table ? table.id : null,
          customerOrderNotes: orderNotes,
          customerDeliveryNotes: deliveryNotes,
          orderItems: map(savedBasket, item => ({
            menuItemId: item.menuItem.id,
            optionItemIds: map(item.optionItems, 'id'),
            singleItemNotes: item.singleItemNotes,
            price: item.addOnItem ? item.menuItem.price : null,
            addOnItem: item.addOnItem,
          })),
        },
      }
    }

    const orderObj = {
      outlet: outlet.id,
      orderItems: map(savedBasket, item => ({
        menuItem: item.menuItem.id,
        optionItems: map(item.optionItems, 'id'),
        singleItemNotes: item.singleItemNotes,
        price: item.addOnItem ? item.menuItem.price : null,
        addOnItem: item.addOnItem,
      })),
      asap: asap,
      deliveryZone: zone,
      customerOrderNotes: orderNotes,
      customerDeliveryNotes: deliveryNotes,
      fulfillmentMethod: fulfilmentChosen.id.toUpperCase(),
      paymentMethod: paymentMethod ? paymentMethod.id : '',
      selectedDeliveryWindow,
    }
    if (needsAddress && address) {
      orderObj['customerDeliveryAddress'] = address.toString()
    }
    orderObj['selectedDeliverySlot'] = moment(selectedDeliverySlot)
    return orderObj
  }

  const setCustomerDeliveryNotes = e => {
    setDeliveryNotes(e.target.value)
  }

  const goToLink = (e, linkLocation) => {
    e.preventDefault()
    history.push(linkLocation)
  }

  const noOrderView = () => {
    const { deliveryZone, restaurant, outletId } = match.params
    const link = `/${deliveryZone}-takeaways/${restaurant}/${outletId}/menu`
    const { noOrderViewWrapper, noOrderHeader, noOrderPara, backButton } =
      classes
    return (
      <div className={noOrderViewWrapper}>
        <h1 className={noOrderHeader}>No items in your order</h1>
        <p className={noOrderPara}>
          Please add items to your order before checking out
        </p>
        <a href="#_" className={backButton} onClick={e => goToLink(e, link)}>
          Add More Items
        </a>
      </div>
    )
  }

  const getDeliveryCost = async () => {
    const { outletId, address } = orderContext.attributes
    const { data } = await client.query({
      query: GET_ADDRESS_DELIVERY_COST_V2,
      variables: { addressId: address, outletId },
      context: { version: 2 },
    })

    return data.deliveryEstimateForAddress.cost
  }

  const validateOrderObject = async (postableObj, e) => {
    const errors = []
    const { fulfilmentChosen } = orderContext.attributes

    if (fulfilmentChosen.id !== 'table') {
      const noDeliveryTime =
        !postableObj.asap && !postableObj.selectedDeliverySlot
      if (noDeliveryTime) {
        errors.push(`Please select a ${fulfilmentChosen.id} time`)
      }

      const hasNoPaymentMethod = !postableObj.paymentMethod
      if (hasNoPaymentMethod) {
        errors.push(`Please select a payment method`)
      }
    }

    const tableNotSelected =
      fulfilmentChosen.id === 'table' && !postableObj.orderData.tableId
    if (tableNotSelected) {
      errors.push('Please select a table')
    }

    const ageNotConfirmed =
      hasAgeResrictedItems && !e.target.ageRestriction.checked
    if (ageNotConfirmed) {
      errors.push(`Please confirm you are over 18`)
    }

    const noDeliveryAddress =
      !postableObj.customerDeliveryAddress &&
      (fulfilmentChosen.id === 'delivery' || fulfilmentChosen.id === 'network')
    if (noDeliveryAddress) {
      errors.push(`Please select a delivery address`)
    }

    const checkNetworkDeliversToAddress =
      fulfilmentChosen &&
      fulfilmentChosen.id === 'network' &&
      postableObj.customerDeliveryAddress
    if (checkNetworkDeliversToAddress) {
      try {
        // if this function errors we cant get a delivery cost
        // so show server error message in toast
        await getDeliveryCost()
      } catch (err) {
        errors.push(err.message)
      }
    }

    const paymentMethodIsCardButNoCardDetails =
      postableObj.paymentMethod &&
      postableObj.paymentMethod.includes('CARD') &&
      !cardToken
    if (paymentMethodIsCardButNoCardDetails) {
      errors.push('Please add a card')
    }

    const valid = errors.length === 0
    if (!valid) {
      each(errors, error => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        })
      })
    }
    return valid
  }

  const orderSuccess = (postOrder, isOrderToTable) => {
    const order = get(
      postOrder,
      isOrderToTable ? 'data.orderToTable' : 'data.addOrder.order',
      {}
    )
    if (order) {
      const { id } = order
      toast.success('Thanks, Order Placed!', {
        position: toast.POSITION.TOP_CENTER,
      })
      orderContext.replaceOrder()
      basketContext.clearItems()
      history.push(`/account/orders/${id}`)
    }
  }

  const orderFailure = (message = 'Error, please try again.') => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    })
    setPlacingOrder(false)
  }

  const addOrderMutation = debounce(
    async ({ mutation, outlet, needsAddress, isOrderToTable, event }) => {
      setPlacingOrder(true)
      const orderObject = await getOrderObject({
        outlet,
        needsAddress,
        isOrderToTable,
      })
      const submissionValid = await validateOrderObject(orderObject, event)
      if (!submissionValid) {
        setPlacingOrder(false)
        return
      }

      if (appliedDiscountId) {
        orderObject.discountId = appliedDiscountId
      }

      // pass a voucher if available
      if (voucherKey) {
        orderObject.voucherKey = voucherKey
      }

      try {
        const postOrder = await mutation({ variables: orderObject })
        const requireAction = isOrderToTable
          ? postOrder.data.orderToTable.requireAction
          : postOrder.data.addOrder.requireAction

        if (stripe) {
          if (requireAction) {
            stripe
              .confirmCardPayment(
                isOrderToTable
                  ? postOrder.data.orderToTable.paymentIntentClientSecret
                  : postOrder.data.addOrder.paymentIntentClientSecret
              )
              .then(action => {
                if (action.error) {
                  orderFailure(action.error.message)
                } else {
                  orderSuccess(postOrder, isOrderToTable)
                }
              })
          } else {
            orderSuccess(postOrder, isOrderToTable)
          }
        } else {
          orderFailure('Error loading payment provider, please try again.')
        }
      } catch (err) {
        each(err.graphQLErrors, errorObj => {
          orderFailure(errorObj.message)
        })
        setPlacingOrder(false)
      }
    },
    250,
    { leading: true, trailing: false }
  )

  const getNotesSectionTitle = () => {
    const { fulfilmentChosen } = orderContext.attributes
    if (!fulfilmentChosen) {
      return ''
    }

    let phrase = fulfilmentChosen.id
    if (fulfilmentChosen.id === 'network') {
      phrase = 'delivery'
    }
    return capitalize(phrase)
  }

  const sendPurchaseToGTM = (data, isOrderToTable) => {
    const order = get(
      data,
      isOrderToTable ? 'orderToTable' : 'addOrder.order',
      {}
    )
    const items = order.orderItems.reduce((acc, orderItem) => {
      const menuItem = orderItem.menuItem
      const existingItem = acc.find(
        ecomItem => ecomItem.item_id === menuItem.id
      )
      if (existingItem) {
        existingItem.quantity = existingItem.quantity + 1
      } else {
        const item = {
          item_name: menuItem.name,
          item_id: menuItem.id,
          item_price: menuItem.price,
          quantity: 1,
        }
        acc = [...acc, item]
      }
      return acc
    }, [])

    gtmContext.pushDataToGTM({
      event: 'purchase',
      ecommerce: {
        purchase: {
          transaction_id: order.id,
          affiliation: 'Website Order',
          value: order.grossTotal,
          shipping: order.fulfillmentCharge,
          currency: 'AUD',
          items,
        },
        items,
      },
    })
  }

  const { orderMode, checkoutText } = getMarketplace()
  const { paymentMethod, fulfilmentChosen } = orderContext.attributes
  const needsCard =
    paymentMethod && paymentMethod.id.toUpperCase().indexOf('CARD') > -1
  const needsAddress =
    fulfilmentChosen.id === 'delivery' || fulfilmentChosen.id === 'network'

  const isOrderToTable = fulfilmentChosen && fulfilmentChosen.id === 'table'
  const outletId = attributes.outletId || match.params.outletId

  const { deliveryDestination, deliveryZone } = orderContext.attributes

  const QUERY_TYPES = {
    COLLECTION: {
      queryName: GET_COLLECTION_TOTALS,
      id: 'collection',
      resField: 'collectionCheckoutTotals',
      variables: { appliedDiscountId },
    },
    POSTCODE: {
      queryName: GET_POSTCODE_DELIVERY_TOTALS,
      variables: {
        appliedDiscountId,
        deliveryPostcode: deliveryDestination
          ? deliveryDestination.destination
          : '',
      },
      id: 'postcode',
      resField: 'deliveryToPostcodeCheckoutTotals',
    },
    ZONE: {
      queryName: GET_ZONE_DELIVERY_TOTALS,
      variables: {
        appliedDiscountId,
        deliveryZoneId: deliveryZone && deliveryZone.id ? deliveryZone.id : '',
      },
      id: 'zone',
      resField: 'deliveryToZoneCheckoutTotals',
    },
    TABLE: {
      queryName: GET_TABLE_TOTALS,
      variables: {},
      id: 'table',
      resField: 'tableCheckoutTotals',
    },
  }

  const getFulfilmentId = () => {
    if (fulfilmentChosen && orderContext.attributes) {
      if (fulfilmentChosen.id === 'delivery') {
        if (
          orderContext.attributes.deliveryZone &&
          orderContext.attributes.deliveryZone.id
        ) {
          return 'zone'
        }
        return 'postcode'
      }
      return fulfilmentChosen.id
    }
    return 'postcode'
  }

  useEffect(() => {
    totalRef.current = null
  }, [fulfilmentChosen])

  const getQuantityOfItemInBasket = id => {
    const itemIds = basket.map(({ menuItem }) => menuItem.id)
    return itemIds.filter(basketItemId => basketItemId === id).length
  }

  useEffect(() => {
    const addOnItemsInBasket = basket.filter(item => item.addOnItem)
    setTotalQuantityOfAddOnItems(addOnItemsInBasket.length)
  }, [addOnMenuItemsOpen])

  const queryType = QUERY_TYPES[getFulfilmentId().toUpperCase()]

  return (
    <div>
      <>
        {!isLoggedIn || !basket.length ? (
          <>
            {!isLoggedIn && <LoginPrompt />}

            {!basket.length && noOrderView()}
          </>
        ) : queryType ? (
          <Query
            query={queryType.queryName}
            variables={{
              outletId: outletId,
              basketSubtotal: basketContext.subTotal,
              ...queryType.variables,
            }}
            context={{ version: 2 }}
          >
            {({ data, error = null, refetch }) => {
              if (!error && data[queryType.resField]) {
                if (moment(data[queryType.resField].expiryAt) < moment()) {
                  refetch()
                }

                const { total } = data[queryType.resField]
                //Set totalRef here to persist total through basketTotal changes (if customer adds add on items, offer at same price)
                if (!totalRef.current) {
                  totalRef.current = total
                }

                if (outlet.restaurant && outlet.restaurant.allowAddOnItems)
                  return (
                    <Query
                      query={GET_ADD_ON_MENUS}
                      variables={{
                        outletId,
                        basketTotal: totalRef.current,
                        fulfilmentMethods: [toUpper(fulfilmentChosen.label)],
                      }}
                      context={{ version: 2 }}
                      fetchPolicy={'network-only'}
                    >
                      {({ data, loading, error = null }) => {
                        const addOnMenuItems = data && data.addOnMenuItems
                        if (
                          !error &&
                          !loading &&
                          addOnMenuItemsOpen &&
                          addOnMenuItems &&
                          addOnMenuItems.length
                        ) {
                          return (
                            <div style={{ paddingTop: '10px' }}>
                              <div style={{ textAlign: 'left' }}>
                                <p className={classes.pageHeader}>
                                  Offers just for you
                                </p>
                                <p
                                  style={{
                                    fontSize: '14px',
                                    padding: '10px 0px',
                                  }}
                                >
                                  {outlet.restaurant.name} would like to offer
                                  you a discount on any of these additional
                                  items:
                                </p>
                              </div>
                              <br />
                              <br />
                              <form>
                                {addOnMenuItems.map(item => (
                                  <div
                                    className={classes.itemContainer}
                                    key={item.id}
                                  >
                                    <div className={classes.addOnItemContainer}>
                                      <div className={classes.nameContainer}>
                                        <p style={{ marginTop: '2px' }}>
                                          {item.name}
                                        </p>
                                        {item.isVegan && (
                                          <span
                                            className={classes.itemInformation}
                                            title="Vegan"
                                          >
                                            <img
                                              src={veganImage}
                                              title={`${item.name} is vegan`}
                                              alt={`${item.name} is vegan`}
                                            />
                                          </span>
                                        )}
                                        {item.isGlutenFree && (
                                          <span
                                            className={classes.itemInformation}
                                            title="Gluten free"
                                          >
                                            <img
                                              src={glutenFreeImage}
                                              alt={`${item.name} is gluten free`}
                                              title={`${item.name} is gluten free`}
                                            />
                                          </span>
                                        )}
                                        {item.isVegetarian && !item.isVegan && (
                                          <span
                                            className={classes.itemInformation}
                                            title="Vegetarian"
                                          >
                                            <img
                                              src={vegetarianImage}
                                              alt={`${item.name} is vegetarian`}
                                              title={`${item.name} is vegetarian`}
                                            />
                                          </span>
                                        )}
                                        {item.isDairyFree && !item.isVegan && (
                                          <span
                                            className={classes.itemInformation}
                                            title="Dairy free"
                                          >
                                            <img
                                              src={dairyFreeImage}
                                              alt={`${item.name} is dairy free`}
                                              title={`${item.name} is dairy free`}
                                            />
                                          </span>
                                        )}
                                        {item.ageRestricted && (
                                          <ReactSVG
                                            src={adultContent}
                                            className={classes.ageRestricted}
                                            wrapper="span"
                                          />
                                        )}
                                      </div>

                                      <div className={classes.priceIndicator}>
                                        {item.addOnMaxPrice !== item.price && (
                                          <p
                                            className={
                                              classes.maxPriceIndicator
                                            }
                                          >
                                            {`£${penceToPounds(
                                              item.addOnMaxPrice
                                            )}`}
                                          </p>
                                        )}

                                        <p>{`£${penceToPounds(item.price)}`}</p>
                                      </div>

                                      <span className={classes.itemPrice}>
                                        <div className={classes.footerButton}>
                                          <ReactSVG
                                            src={minusSVG}
                                            className={classes.qtyControlIcon}
                                            wrapper="span"
                                            onClick={() => {
                                              event.preventDefault()
                                              if (
                                                getQuantityOfItemInBasket(
                                                  item.id
                                                ) > 0
                                              ) {
                                                const itemInBasket =
                                                  basket.find(
                                                    ({ menuItem: { id } }) =>
                                                      id === item.id
                                                  )
                                                if (itemInBasket) {
                                                  basketContext.removeItem(
                                                    itemInBasket.id
                                                  )
                                                  setTotalQuantityOfAddOnItems(
                                                    totalQuantityOfAddOnItems -
                                                      1
                                                  )
                                                }
                                              }
                                            }}
                                          />
                                        </div>
                                        <p
                                          className={classes.quantityIndicator}
                                        >
                                          {' '}
                                          {getQuantityOfItemInBasket(item.id)}
                                        </p>

                                        <div className={classes.footerButton}>
                                          <ReactSVG
                                            src={addSVG}
                                            className={classes.qtyControlIcon}
                                            wrapper="span"
                                            onClick={e => {
                                              e.preventDefault()
                                              basketContext.addItem({
                                                menuItem: item,
                                                optionItems: [],
                                                singleItemNotes: '',
                                                price: item.price,
                                                addOnItem: true,
                                              })
                                              setTotalQuantityOfAddOnItems(
                                                totalQuantityOfAddOnItems + 1
                                              )
                                            }}
                                          />
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                ))}

                                <div className={classes.formFooter}>
                                  <Button
                                    type="submit"
                                    className={classes.confirmButton}
                                    appearance="positive"
                                    capitalize
                                    fullWidth
                                    onClick={() => {
                                      setAddOnMenuItemsOpen(false)
                                    }}
                                  >
                                    {totalQuantityOfAddOnItems > 0
                                      ? 'Checkout'
                                      : 'Skip'}
                                  </Button>
                                </div>
                              </form>
                            </div>
                          )
                        }
                        return (
                          <CheckoutFormContent
                            isOrderToTable={isOrderToTable}
                            orderMode={orderMode}
                            sendPurchaseToGTM={sendPurchaseToGTM}
                            outlet={outlet}
                            needsAddress={needsAddress}
                            classes={classes}
                            needsCard={needsCard}
                            conditionallySetCardToken={
                              conditionallySetCardToken
                            }
                            checkoutText={checkoutText}
                            getNotesSectionTitle={getNotesSectionTitle}
                            hasAgeResrictedItems={hasAgeResrictedItems}
                            setCustomerDeliveryNotes={setCustomerDeliveryNotes}
                            placingOrder={placingOrder}
                            addOrderMutation={addOrderMutation}
                            setPlacingOrder={setPlacingOrder}
                          />
                        )
                      }}
                    </Query>
                  )
              }
              return (
                <CheckoutFormContent
                  isOrderToTable={isOrderToTable}
                  orderMode={orderMode}
                  sendPurchaseToGTM={sendPurchaseToGTM}
                  outlet={outlet}
                  needsAddress={needsAddress}
                  classes={classes}
                  needsCard={needsCard}
                  conditionallySetCardToken={conditionallySetCardToken}
                  checkoutText={checkoutText}
                  getNotesSectionTitle={getNotesSectionTitle}
                  hasAgeResrictedItems={hasAgeResrictedItems}
                  setCustomerDeliveryNotes={setCustomerDeliveryNotes}
                  placingOrder={placingOrder}
                  addOrderMutation={addOrderMutation}
                  setPlacingOrder={setPlacingOrder}
                />
              )
            }}
          </Query>
        ) : (
          <CheckoutFormContent
            isOrderToTable={isOrderToTable}
            orderMode={orderMode}
            sendPurchaseToGTM={sendPurchaseToGTM}
            outlet={outlet}
            needsAddress={needsAddress}
            classes={classes}
            needsCard={needsCard}
            conditionallySetCardToken={conditionallySetCardToken}
            checkoutText={checkoutText}
            getNotesSectionTitle={getNotesSectionTitle}
            hasAgeResrictedItems={hasAgeResrictedItems}
            setCustomerDeliveryNotes={setCustomerDeliveryNotes}
            placingOrder={placingOrder}
            addOrderMutation={addOrderMutation}
            setPlacingOrder={setPlacingOrder}
          />
        )}
      </>
    </div>
  )
}

const CheckoutWrapper = props => {
  const { classes, match } = props
  const { stripePublicId } = getMarketplace()
  const { items, removeAddOnItems } = useContext(BasketContext)

  return (
    <Query
      query={OUTLET_SUMMARY}
      variables={{ id: match.params.outletId }}
      context={{ version: 2 }}
    >
      {({ data, loading }) => {
        const restaurant = get(data, 'outlet.restaurant', {
          name: '',
        })
        const outlet = get(data, 'outlet', {
          paymentMethods: [],
        })

        return (
          <div className={cx('wrapper container-fluid', classes.listWrapper)}>
            <div className="row">
              <div className="col-xs-12 col-md-3">
                <Alert
                  confirm={() => {
                    removeAddOnItems()
                    const { deliveryZone, restaurant, outletId } =
                      props.match.params
                    const link = `/${deliveryZone}-takeaways/${restaurant}/${outletId}/menu`
                    props.history.push(link)
                  }}
                  question={
                    <p>
                      Navigating away from Checkout will remove special discount
                      items marked with 'Offer'
                    </p>
                  }
                  condition={Boolean(items.find(({ addOnItem }) => addOnItem))}
                >
                  <StickySideBar
                    noStick
                    title={
                      <>
                        <h2 className={classes.checkoutTitle}>Checkout</h2>
                        <BackLink
                          alt={`Back to ${restaurant.name}.`}
                          linkText="Back"
                        />
                      </>
                    }
                  ></StickySideBar>
                </Alert>
              </div>

              <div className="col-xs-12 col-md-6">
                <div className={classes.columnWrapper}>
                  <StripeProvider apiKey={stripePublicId}>
                    <Elements>
                      <CheckoutForm
                        outlet={outlet}
                        loading={loading}
                        {...props}
                      />
                    </Elements>
                  </StripeProvider>
                </div>
              </div>
              <div className="col-xs-12 col-md-3">
                <Cart />
              </div>
            </div>
          </div>
        )
      }}
    </Query>
  )
}

const CheckoutForm = withStyles(styles)(injectStripe(CheckoutEl))
const Checkout = withStyles(styles)(CheckoutWrapper)
export default Checkout
